<route>
{
  "meta": {
    "permission": [
      "customer.view_customer"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-tabs
      class="mb-2"
      centered
      color="primary"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      v-model="tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab href="#tab-active">
        <span v-if="$vuetify.breakpoint.smAndUp" class="mt-2">
          {{ $t('active') }}
        </span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp">
          fas fa-check-double
        </v-icon>
      </v-tab>
      <v-tab href="#tab-inactive">
        <span v-if="$vuetify.breakpoint.smAndUp" class="mt-2">
          {{ $t('inactive') }}
        </span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-times-circle</v-icon>
      </v-tab>
    </v-tabs>
    <history
      ref="history"
      :to-edit="toEdit"
      :type="'customer'"
      @done="reload = true"
      v-model="dialog"
    />
    <i-table
      api="customer"
      app="customer.customer"
      :otherBtn="customerBtn.invoices || customerBtn.product"
      :otherBtn2="
        customerBtn.add_order && this.tabs === 'tab-active' ? true : false
      "
      :headers="headers"
      :opt="{
        active: this.tabs === 'tab-active' ? true : false,
        company_id: this.firstCompany
      }"
      :title="$tc('customer', 2)"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRouteEdit"
    >
      <template v-slot:item.permanent_customer="{ item }">
        <p class="mb-0">
          {{ item.permanent_customer ? $t('permanent') : $t('sporadic') }}
        </p>
      </template>
      <template v-slot:otherBtn="{ item }">
        <i-btn
          classes="mx-1"
          color="info"
          icon="fas fa-chart-pie"
          outlined
          :title="$tc('history_customer', 1)"
          @click="showHistory(item)"
        ></i-btn>
      </template>
      <template v-slot:otherBtn2="{ item }">
        <i-btn
          classes="mx-1"
          color="info"
          icon="fas fa-list-ol"
          outlined
          :title="` ${$t('create', { model: $tc('sale_order', 1) })}`"
          @click="createOrder(item)"
        ></i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import history from '../../components/history/modal.vue'
export default {
  components: {
    history
  },
  data() {
    return {
      tabs: 'tab-active',
      reload: false,
      dialog: false,
      toEdit: null,
      firstCompany: 0
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('company_name'),
          value: 'company_name'
        },
        {
          text: this.$t('first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('last_name'),
          value: 'last_name'
        },
        {
          text: this.$tc('email', 1),
          value: 'email'
        },
        {
          text: this.$t('phone_number'),
          value: 'phone'
        },
        {
          text: this.customerBtn.level ? this.$tc('level', 1) : '',
          value: this.customerBtn.level ? 'level.name' : ''
        },
        {
          text: this.$t('concurrence'),
          value: 'permanent_customer'
        },
        {
          text:
            this.customerBtn.edit ||
            this.customerBtn.delete ||
            this.customerBtn.invoices ||
            this.customerBtn.product
              ? this.$t('action')
              : '',
          value:
            this.customerBtn.edit ||
            this.customerBtn.delete ||
            this.customerBtn.invoices ||
            this.customerBtn.product
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies'
    }),
    customerBtn() {
      return {
        delete:
          this.getPermissions([`customer.delete_customer`]) || this.isAdmin,
        edit: this.getPermissions([`customer.change_customer`]) || this.isAdmin,
        invoices:
          this.getPermissions([`Invoices.view_invoices`]) || this.isAdmin,
        level: this.getPermissions([`base_config.view_level`]) || this.isAdmin,
        product: this.getPermissions([`product.view_product`]) || this.isAdmin,
        terms: this.getPermissions([`base_config.view_term`]) || this.isAdmin,
        add_order: this.getPermissions([`sales.add_order`]) || this.isAdmin
      }
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'customer-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    changeRouteEdit(item) {
      this.$router.push({
        name: 'customer-pkEdit',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    showHistory(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    /**
     * setCompany
     * Método para asignar la compañía a la que pertenece el usuario al
     * filtro de la empresa de la tabla
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    },
    /**
     * createOrder
     * Método para guardar el id del cliente en el $store y redirigir a
     * crear orden de venta
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    createOrder(item) {
      this.$store.commit('sales/customerToSale', item)
      this.$router.push({
        name: 'sale-pk',
        params: { pk: this.codification({ code: 'create' }) }
      })
    }
  },
  created() {
    this.setCompany()
  }
}
</script>
